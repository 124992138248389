<template>
  <div class="area">
    <div class="left">
      科室：
      <b-link class="item" :class="dept != '' ? '' : 'active'" @click="selectDept"
        >不限</b-link
      >
    </div>
    <div class="right">
      <div
        class="item"
        :class="dept == item.id ? 'active' : ''"
        v-for="(item, index) in depts"
        :key="index"
        @click="selectDept(item)"
      >
        {{ item.collection_name }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      depts: [],
      dept: "",
    };
  },
  mounted() {
    this.getDepts();
  },
  methods: {
    getDepts() {
      this.$http.collections({ page: "", limit: "" }).then((res) => {
        let { data, code } = res;
        if (code == 1) {
          this.depts = data.items;
        } else {
          this.$store.commit("showToast", "获取科室失败");
        }
      });
    },
    selectDept(item) {
      if (item.id) {
        this.dept = item.id;
        this.$emit("change", item);
      } else {
        this.dept = "";
        this.$emit("change", null);
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "./menus.scss";
</style>
