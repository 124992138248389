<template>
  <div class="page">
    <div class="top">
      <img class="img" src="../../static/community/banner.png" />
    </div>
    <div class="content">
      <div class="title">名医专栏</div>
      <div class="border-line">
        <select-menu @change="changeMenu"></select-menu>
        <dept-menu @change="changeDept"></dept-menu>
        <level-menu @change="changeLevel"></level-menu>
      </div>
      <div class="menus">
        <div
          class="item"
          v-for="(item, index) in menus"
          :key="index"
          :class="item.id == active ? 'active' : ''"
          @click="selectMenu(item.id)"
        >
          <b-link>{{ item.text }}</b-link>
        </div>
      </div>

      <div class="list">
        <b-overlay :show="show" rounded="sm">
          <div
            class="info"
            v-for="(item, index) in doctors"
            :key="index"
            @click="openPage(item.id)"
          >
            <div class="doctor-avatar">
              <img :src="$host + item.avatar" class="img" />
            </div>
            <div class="doctor-info">
              <div class="left">
                <div class="nickname">{{ item.nickname }}</div>
                <div class="dept">
                  {{ item.position ? item.position.name : "未认证医生" }}
                </div>

                <div class="like">擅长：{{ item.self.join("、") }}</div>
                <div class="desc">
                  主治医师医生荣誉：中国医师协会与整形医师分会面部整形专业委员会委员中国医师协会美容与整形医师分会眼部整形专业委员会会员中国医师协会美容与整形医师分会脂肪整形分会会员
                  中华医学会整形外科分会……
                </div>
                <div class="tools">
                  <b-link class="item">
                    <img class="tools-icon" src="../../static/doctor/pice.png" />
                    <span>￥{{ item.graphic_amount }}</span>
                  </b-link>
                  <b-link class="item">
                    <img class="tools-icon" src="../../static/doctor/phone.png" />
                    <span>￥{{ item.voice_amount }}</span>
                  </b-link>
                  <b-link class="item">
                    <img class="tools-icon" src="../../static/doctor/video.png" />
                    <span>￥{{ item.video_amount }}</span>
                  </b-link>
                </div>
              </div>
              <div class="right">
                <div class="rate">
                  <div
                    style="
                      width: 120px;
                      line-height: 32px;
                      border-right: 1px solid #e6e7e9;
                    "
                  >
                    <b-link>服务人次: {{ item.user_order }}</b-link
                    ><br />
                    <b-link>好评率: {{ item.favorable_rate }}%</b-link><br />
                    <b-link>职业年限: </b-link>
                  </div>
                  <div style="width: 120px; text-align: center">
                    <div style="height: 32px; line-height: 32px">
                      <span style="color: #fb776a; font-size: 24px; margin-right: 5px">{{
                        (Number(item.favorable_rate) / 20).toFixed(2)
                      }}</span
                      >分
                    </div>
                    <div style="height: 32px; line-height: 32px">
                      <b-icon
                        style="color: #ffc000; margin-right: 3px"
                        icon="star-fill"
                      ></b-icon>
                      <b-icon
                        style="color: #ffc000; margin-right: 3px"
                        icon="star-fill"
                      ></b-icon>
                      <b-icon
                        style="color: #ffc000; margin-right: 3px"
                        icon="star-fill"
                      ></b-icon>
                      <b-icon
                        style="color: #ffc000; margin-right: 3px"
                        icon="star-fill"
                      ></b-icon>
                      <b-icon
                        style="color: #ffc000; margin-right: 3px"
                        icon="star-fill"
                      ></b-icon>
                    </div>
                    <div
                      style="
                        height: 32px;
                        line-height: 32px;
                        font-size: 13px;
                        color: #999;
                      "
                    >
                      来自{{ item.user_order }}人评价
                    </div>
                  </div>
                </div>
                <div class="tags">
                  <div class="tag">耐心细致</div>
                  <div class="tag">业内专家</div>
                  <div class="tag">可开处方</div>
                </div>
              </div>
            </div>
          </div>
        </b-overlay>
      </div>

      <b-pagination
        style="margin: 20px 0"
        v-model="page"
        :total-rows="count"
        :per-page="limit"
        aria-controls="my-table"
      ></b-pagination>
    </div>
  </div>
</template>
<script>
import selectMenu from "../doctors/selectMenu.vue";
import deptMenu from "../doctors/deptMenu.vue";
import levelMenu from "../doctors/levelMenu.vue";
export default {
  components: {
    selectMenu,
    deptMenu,
    levelMenu,
  },
  data() {
    return {
      active: "",
      menus: [
        {
          id: "",
          text: "智能排序",
        },
        {
          id: "orders",
          text: "接诊数高到低",
        },
        {
          id: "comment",
          text: "评论高到低",
        },
        {
          id: "cases",
          text: "案例高到低",
        },
      ],
      limit: 5,
      page: 1,
      level: "",
      dept: "",
      city: "",
      order: "",
      doctors: [],
      count: "",
      show: false,
    };
  },
  watch: {
    page: function () {
      this.getDoctors();
    },
    city: function () {
      this.getDoctors();
    },
    level: function () {
      this.getDoctors();
    },
    dept: function () {
      this.getDoctors();
    },
    active: function () {
      this.getDoctors();
    },
  },
  methods: {
    selectMenu(id) {
      this.active = id;
    },
    changeMenu(event) {
      this.city = event ? event.name : "";
    },
    changeDept(event) {
      this.dept = event ? event.id : "";
    },
    changeLevel(event) {
      this.level = event ? event.id : "";
    },
    getDoctors() {
      let data = {
        collection_id: this.dept,
        position_id: this.level,
        area: this.city,
        order: this.active,
        limit: this.limit,
        page: this.page,
      };
      if (this.show) {
        return;
      } else {
        this.show = true;
        this.$http
          .doctors(data)
          .then((res) => {
            this.show = false;
            let { data, code, msg } = res;
            if (code == 1) {
              let list = data.items;
              list.forEach((item) => {
                let arr = [];
                item.collection_list.forEach((i) => {
                  arr.push(i.collection_name);
                });
                item.self = arr;
              });
              console.log(list);
              this.doctors = data.items;
              this.count = data.total;
            } else {
              this.$store.commit("showToast", msg);
            }
          })
          .catch(() => {
            this.show = false;
          });
      }
    },
    openPage(id) {
      this.$router.push({
        path: "/doctor_info",
        query: {
          id: id,
        },
      });
    },
  },
  mounted() {
    this.getDoctors();
  },
};
</script>
<style scoped lang="scss">
a {
  text-decoration: none;
  color: #666;
}
@import "./doctor.scss";
</style>
