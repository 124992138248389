<template>
  <div class="area">
    <div class="left">
      地区：
      <b-link class="item" :class="city!=''?'':'active'" @click="selectArea">不限</b-link>
    </div>
    <div class="right">
      <div
        class="item"
        :class="city == item.id?'active':''"
        v-for="(item, index) in citys"
        :key="index"
        @click="selectArea(item)"
      >
        {{ item.name }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      citys: [],
      city: "",
    };
  },
  mounted() {
    this.hotCity();
  },
  methods: {
    hotCity() {
      this.$http.hot({}).then((res) => {
        // console.log(res);
        let data = res.data;
        if (res.code == 1) {
          data.forEach((element) => {
            this.citys.push({
              name: element.name,
              id: element.id,
            });
          });
        }
      });
    },
    selectArea(item) {
        if(item.id) {
            this.city = item.id
            this.$emit("change", item)
        }else {
            console.log('log')
            this.city = ""
            this.$emit("change", null)
        }        
    }
  },
};
</script>
<style scoped lang="scss">
@import "./menus.scss";
</style>
