<template>
  <div class="area">
    <div class="left">
      职称：
      <b-link class="item" :class="level != '' ? '' : 'active'" @click="selectLevel"
        >不限</b-link
      >
    </div>
    <div class="right">
      <b-link
        class="item"
        v-for="(item, index) in levels"
        :class="level == item.id ? 'active' : ''"
        :key="index"
        @click="selectLevel(item)"
      >
        {{ item.name }}
      </b-link>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      levels: [],
      level: "",
    };
  },
  mounted() {
    this.getLevels();
  },
  methods: {
    getLevels() {
      this.$http.level({}).then((res) => {
        let { data, code } = res;
        if (code == 1) {
          this.levels = data;
        } else {
          this.$store.commit("showToast", "获取医生职称失败");
        }
      });
    },
    selectLevel(item) {
      if (item.id) {
        this.level = item.id;
        this.$emit("change", item);
      } else {
        this.level = "";
        this.$emit("change", null);
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "./menus.scss";
</style>
